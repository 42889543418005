import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { format, isBefore, startOfDay } from 'date-fns';
import theme from 'utils/theme';

type Props = {
    isActive?: boolean;
    date: Date;
    onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        flex: 1,
        padding: '3px 0',
    },
    pointer: {
        cursor: 'pointer',
    },
    title: {
        fontWeight: 'normal',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
    },
    subtitle: {
        fontWeight: 'normal',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
    },
}));

const WeekDateItem = ({ date, isActive, onClick }: Props) => {
    const classes = useStyles();

    const isDisabled = isBefore(date, startOfDay(new Date()));

    const backgroundColor = isDisabled
        ? theme.palette.grey[50]
        : isActive
        ? theme.palette.primary.main
        : theme.palette.common.white;
    const textColor = isDisabled
        ? theme.palette.grey.A200
        : isActive
        ? theme.palette.common.white
        : theme.palette.text.primary;

    return (
        <Box
            style={{ backgroundColor }}
            className={classNames({
                [classes.container]: true,
                [classes.pointer]: !isDisabled,
            })}
            onClick={() => {
                !isDisabled && onClick();
            }}
        >
            <Typography style={{ color: textColor }} className={classes.title}>
                {format(date, 'dd')}
            </Typography>
            <Typography style={{ color: textColor }} className={classes.subtitle}>
                {format(date, 'MMM')}
            </Typography>
        </Box>
    );
};

export default WeekDateItem;
