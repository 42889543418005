import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles, IconButton } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './datePicker.css';
import { useSettings } from '../../services/useSettings';

const useStyles = makeStyles((theme) => ({
    baseStyles: {
        padding: 5,
        top: 20,
        position: 'absolute',
        backgroundColor: theme.palette.grey[200],
        '&:hover': {
            backgroundColor: theme.palette.grey[400],
        },
    },
    navPrev: {
        left: 22,
    },
    navNext: {
        right: 22,
    },
}));

export const DatePicker = ({
    showInputs = true,
    startDateId,
    endDateId,
    displayFormat,
    orientation,
    focusedInput,
    onFocusChange,
    ...rest
}) => {
    const classes = useStyles();
    const [localFocusedInput, setLocalFocusedInput] = useState(null);
    const { settings } = useSettings();
    const handleFocusChange = (eventFocusedInput) => {
        if (onFocusChange) {
            return onFocusChange(eventFocusedInput);
        }

        setLocalFocusedInput(eventFocusedInput);
    };

    const newProps = { ...rest };
    const Component = showInputs ? DateRangePicker : DayPickerRangeController;

    if (showInputs) {
        newProps.startDateId = startDateId;
        newProps.endDateId = endDateId;
        newProps.displayFormat = displayFormat;
    }

    if (newProps.style) {
        delete newProps.style;
    }

    return (
        <div data-testid="DatePicker">
            <Component
                navPrev={
                    orientation === 'vertical' ? undefined : (
                        <IconButton className={classnames(classes.baseStyles, classes.navPrev)}>
                            <ChevronLeft />
                        </IconButton>
                    )
                }
                navNext={
                    orientation === 'vertical' ? undefined : (
                        <IconButton className={classnames(classes.baseStyles, classes.navNext)}>
                            <ChevronRight />
                        </IconButton>
                    )
                }
                minimumNights={0}
                focusedInput={focusedInput ? focusedInput : localFocusedInput}
                onFocusChange={handleFocusChange}
                orientation={orientation}
                firstDayOfWeek={settings?.rota?.startDay?.settingStartDayOfWeek}
                {...newProps}
            />
        </div>
    );
};

DatePicker.propTypes = {
    startDate: PropTypes.object,
    startDateId: (props, propName) => {
        if (
            Boolean(props.showInputs) === true &&
            (props[propName] === undefined || typeof props[propName] !== 'string')
        ) {
            return new Error("startDateId is required when showing inputs with type of 'string'");
        }
    },
    endDate: PropTypes.object,
    endDateId: (props, propName) => {
        if (
            Boolean(props.showInputs) === true &&
            (props[propName] === undefined || typeof props[propName] !== 'string')
        ) {
            return new Error("endDateId is required when showing inputs with type of 'string'");
        }
    },
    onDatesChange: PropTypes.func,
    isDayBlocked: PropTypes.func,
    onFocusChange: PropTypes.func,
    showInputs: PropTypes.bool,
    focusedInput: PropTypes.string,
    displayFormat: PropTypes.string,
    orientation: PropTypes.string,
};
