import PlusIcon from 'mdi-material-ui/Plus';
import { makeStyles, Grid, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSettings } from '../services/useSettings';
import { useState } from 'react';
import { MobileAddShiftDialog } from 'containers/Schedule/localComponents/mobileAddShift/AddShiftDialog';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    labelOnRight: {
        position: 'fixed',
        marginLeft: 80,
        lineHeight: '44px',
    },
    requestTimeOffFab: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    },
    requestTimeOffFabLeft: {
        left: 2,
        position: 'absolute',
    },
}));

type Props = {
    onClick: () => void;
};

export const IconButtonAddShift = ({ onClick, ...rest }: Props) => {
    const classes = useStyles();
    const [showAddModal, setShowModal] = useState(false);
    const { t } = useTranslation();
    const {
        settings: { jiraServiceDeskWidget },
    } = useSettings();

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs>
                <Typography
                    variant="h6"
                    className={classNames({
                        [classes.label]: true,
                        [classes.labelOnRight]: jiraServiceDeskWidget.enabled,
                    })}
                >
                    {t('schedule:add_shift_button')}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <MobileAddShiftDialog
                    button={
                        <IconButton
                            onClick={() => {
                                setShowModal(true);
                                onClick();
                            }}
                            className={classNames({
                                [classes.requestTimeOffFab]: true,
                                [classes.requestTimeOffFabLeft]: jiraServiceDeskWidget.enabled,
                            })}
                            {...rest}
                        >
                            <PlusIcon color="primary" />
                        </IconButton>
                    }
                    isVisible={showAddModal}
                    onClose={function (): void {
                        setShowModal(false);
                    }}
                />
            </Grid>
        </Grid>
    );
};
