import { SVGProps } from 'react';

const CircleErrorIcon = ({ width = 26, height = 27, ...props }: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 26 27" {...props}>
        <path
            fill="#D1291E"
            fillRule="evenodd"
            d="M24 13.5c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11 11 4.925 11 11Zm2 0c0 7.18-5.82 13-13 13s-13-5.82-13-13S5.82.5 13 .5s13 5.82 13 13ZM19.498 7a.92.92 0 0 1 0 1.3L14.3 13.498l5.202 5.202a.92.92 0 1 1-1.3 1.3L13 14.798 7.798 20a.92.92 0 1 1-1.3-1.3l5.202-5.202L6.502 8.3a.92.92 0 1 1 1.3-1.3L13 12.198 18.198 7a.92.92 0 0 1 1.3 0Z"
            clipRule="evenodd"
        />
    </svg>
);
export default CircleErrorIcon;
