import { useMemo, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { Typography, makeStyles, Grid, Fab } from '@material-ui/core';
import classNames from 'classnames';
import { IconButtonRequest } from './IconButtonRequest';
import { useIsMobileOrTablet } from 'utils/components/helpers';
import { useTranslation } from 'react-i18next';
import { useIsTimeOffEnabled, useSettings } from '../services/useSettings';
import { IconButtonAddShift } from './IconButtonAddShift';
import { checkDateIsInWeekFeature } from 'containers/Schedule/helpers';
import { useSelector } from 'react-redux';
import { selectStartDate } from 'containers/Schedule/store/scheduleSelector';
import { useUser } from 'services/auth';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.9,
        zIndex: 9999,
    },
    buttonPosition: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    buttonPositionLeft: {
        bottom: 20,
        left: 10,
        width: 50,
        height: 50,
    },
    animation: {
        '-webkit-transition': 'width 2s, height 2s, - webkit - transform 0.4s',
        transition: 'width 2s, height 2s, transform 0.4s',
        '&:hover': {
            '-webkit-transform': 'rotate(135deg)',
            transform: 'rotate(135deg)',
            boxShadow: 'none',
        },
    },
    label: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    labelRight: {
        marginLeft: 80,
        marginBottom: 10,
        lineHeight: 44,
    },
    alignContentRight: {
        textAlign: 'right',
        height: '80px',
        display: 'flex',
    },
    dialogContainer: {
        height: '240px',
    },
}));

export const FabMobileDialog = () => {
    const classes = useStyles();
    const isMobileOrTablet = useIsMobileOrTablet();
    const { t } = useTranslation();
    const [hidden, setHidden] = useState(true);
    const { settings, isSettingsLoading } = useSettings();
    const isTimeOffEnabled = useIsTimeOffEnabled();
    const { pathname } = useLocation();

    const startDate = useSelector(selectStartDate);

    const isFutureWeek = useMemo(() => {
        return checkDateIsInWeekFeature(startDate);
    }, [startDate]);

    const { userDetails } = useUser();

    const handleDialog = () => {
        setHidden((prevState) => !prevState);
    };

    const canAddShift = userDetails.canAddShifts && isFutureWeek && pathname.includes('schedule/your-schedule');

    if (isSettingsLoading || !isMobileOrTablet) {
        return null;
    }

    if (!isTimeOffEnabled) {
        return null;
    }

    const { jiraServiceDeskWidget } = settings;

    if (!isMobileOrTablet) {
        return null;
    }

    return (
        <>
            <div hidden={!hidden}>
                <Fab
                    color="primary"
                    className={classNames({
                        [classes.animation]: true,
                        [classes.buttonPosition]: true,
                        [classes.buttonPositionLeft]: jiraServiceDeskWidget.enabled,
                    })}
                    onClick={handleDialog}
                >
                    {hidden ? <AddIcon /> : <Close />}
                </Fab>
            </div>
            <div hidden={hidden} className={classes.dialog}>
                <Grid
                    container
                    alignItems="flex-end"
                    className={classNames({
                        [classes.dialogContainer]: true,
                        [classes.buttonPosition]: true,
                        [classes.buttonPositionLeft]: jiraServiceDeskWidget.enabled,
                    })}
                    style={{ height: canAddShift ? '240px' : '160px' }}
                >
                    {canAddShift && (
                        <Grid item xs={12} className={classes.alignContentRight}>
                            <IconButtonAddShift onClick={handleDialog} />
                        </Grid>
                    )}

                    <Grid item xs={12} className={classes.alignContentRight}>
                        <IconButtonRequest onClick={handleDialog} />
                    </Grid>

                    <Grid item xs={12} className={classes.alignContentRight}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item xs>
                                <Typography
                                    variant="h6"
                                    className={classNames({
                                        [classes.label]: true,
                                        [classes.labelRight]: jiraServiceDeskWidget.enabled,
                                    })}
                                >
                                    {t('common:close')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Fab
                                    color="primary"
                                    onClick={handleDialog}
                                    className={classNames({
                                        [classes.buttonPosition]: true,
                                        [classes.buttonPositionLeft]: jiraServiceDeskWidget.enabled,
                                    })}
                                >
                                    {hidden ? <AddIcon /> : <Close />}
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
