import './polyfills.js';
import { QueryClientProvider, QueryClient } from 'react-query';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import theme from 'utils/theme';
import { RollBarWrapper } from './errorBoundary';
import { createRoot } from 'react-dom/client';

import './index.css';
import { App } from './App';
import { RedirectOnIe11 } from './components/redirectOnIE';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            retry: false,
        },
    },
});

const container = document.getElementById('root');
if (!container) {
    throw new Error('Cannot find root container');
}

const root = createRoot(container);

root.render(
    <RollBarWrapper>
        <MuiThemeProvider theme={theme}>
            <RedirectOnIe11>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <App />
                    </QueryClientProvider>
                </Provider>
            </RedirectOnIe11>
        </MuiThemeProvider>
    </RollBarWrapper>,
);
