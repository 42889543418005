import DefaultButton from 'components/buttons/DefaultButton';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddShiftDialog } from 'containers/Schedule/localComponents/addShift/AddShiftDialog';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const AddShiftButton = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showAddModal, setShowModal] = useState(false);

    return (
        <AddShiftDialog
            isVisible={showAddModal}
            button={
                <DefaultButton
                    size="large"
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setShowModal(true)}
                >
                    &#43; {t('schedule:add_shift_button')}
                </DefaultButton>
            }
            onClose={() => {
                setShowModal(false);
            }}
        />
    );
};

export default AddShiftButton;
