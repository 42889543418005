import {
    Box,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
    useTheme,
} from '@material-ui/core';
import TimeSelector from 'components/TimeSelector';
import WeekDateItem from 'containers/Schedule/localComponents/addShift/WeekDateItem';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDateOfWeek } from 'containers/Schedule/helpers';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import { AddShiftType } from 'containers/Schedule/localComponents/addShift/AddShiftDialog';
import { deleteDraftShift, updateDraftShifts } from 'containers/Schedule/store/scheduleSlice';
import moment from 'moment';
import { useUserContracts } from 'services/useHoliday';
import DeleteIcon from '@material-ui/icons/Delete';
import { selectDraftShifts } from 'containers/Schedule/store/scheduleSelector';
import { useEffect, useMemo } from 'react';
import { selectStartDate } from 'containers/Schedule/store/scheduleSelector';
import { RevisionDataType, RoleDataType } from 'containers/Schedule/type/scheduleType';
import { getShiftContractList } from 'containers/RequestTimeOff/utils/getRevisionsList';
import { useGetRotasStatus } from 'services/useShift';

const useStyles = makeStyles((theme) => ({
    contract: {
        display: 'flex',
        gap: 20,
    },
    label: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
    },
    labelText: {
        fontSize: 16,
        color: theme.palette.common.black,
    },
    dayOfWeekContainer: {
        gap: 20,
    },
    dayOfWeekWrapper: {
        borderColor: '#C5C5C5',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        overflow: 'hidden',
    },
    days: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#C5C5C5',
        padding: '5px 10px',
    },
    dayItemWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
    },
    formControlStyle: {
        width: '100%',
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        padding: 0,
    },
    deleteWrapper: {
        display: 'flex',
        gap: 5,
        justifyContent: 'flex-end',
    },
    deleteText: {
        color: theme.palette.error.dark,
        fontWeight: 'normal',
        fontSize: 14,
    },
}));

type Props = {
    index: number;
    shift: AddShiftType;
    isShowWeek?: boolean;
};

const AddShiftContent = ({ shift, index, isShowWeek = true }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { inputStyle } = (theme as unknown).requestTimeOffForm;
    const draftShifts: AddShiftType[] = useSelector(selectDraftShifts);
    const currentShift = draftShifts[index];

    const { userContracts } = useUserContracts();
    const { shopRotaStatus } = useGetRotasStatus();
    let contracts = getShiftContractList(userContracts).revisions;
    contracts = contracts.filter((contract) => shopRotaStatus[contract.contractId]);

    const isNameDisable = contracts.length === 1;

    const startDate = useSelector(selectStartDate);
    const dayOfWeek = getDateOfWeek(parseISO(startDate));

    const handlePressDay = (day: Date) => {
        const currentDay = format(day, 'yyyy-MM-dd');
        let newDays = [...shift.days];

        if (shift.days.includes(currentDay)) {
            newDays = shift.days.filter((item) => item !== currentDay);
        } else {
            newDays = [currentDay, ...newDays];
        }

        dispatch(updateDraftShifts({ index, data: { days: newDays } }));
    };

    const handleDeleteSection = () => {
        dispatch(deleteDraftShift({ index }));
    };

    const roles = useMemo(() => {
        const currentContract = contracts.find(
            (item: { contractId: number | string }) => item.contractId === currentShift.contract,
        );
        return currentContract?.roles ?? [];
    }, [currentShift.contract, contracts]);

    useEffect(() => {
        if (contracts.length > 0) {
            dispatch(
                updateDraftShifts({
                    index,
                    data: {
                        contract: contracts[0].contractId,
                        department_name: contracts[0].shopName,
                    },
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contracts.length, index]);

    return (
        <Box display="flex" flexDirection="column" gridGap={20} flex={1} alignSelf="center">
            <Grid container className={classes.contract}>
                <Grid item xs={3} alignItems="flex-end" className={classes.label}>
                    <InputLabel className={classes.labelText} htmlFor={'contractInputId'}>
                        {t('portal:contract')}
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <Select
                            id={'contractInputId'}
                            name="contract"
                            type="text"
                            data-testid="contract-select"
                            value={shift.contract ?? ''}
                            onChange={(data) => {
                                const currentContract = contracts.find(
                                    (item: RevisionDataType) => item.contractId === data.target.value,
                                );
                                dispatch(
                                    updateDraftShifts({
                                        index,
                                        data: {
                                            contract: data.target.value,
                                            department_name: currentContract.shopName,
                                        },
                                    }),
                                );
                            }}
                            disabled={isNameDisable}
                            style={inputStyle}
                            input={<OutlinedInput style={inputStyle} error={false} />}
                        >
                            {contracts.map((contract: RevisionDataType) => (
                                <MenuItem
                                    value={contract.contractId}
                                    key={contract.id}
                                    disabled={!contract.selfServiceShifts}
                                >
                                    {`${contract.shopName} - ${contract.positionName}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className={classes.contract}>
                <Grid item xs={3} alignItems="flex-end" className={classes.label}>
                    <InputLabel className={classes.labelText} htmlFor={'contractInputId'}>
                        {t('portal:role')}
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <Select
                            id={'roleId'}
                            name="role"
                            type="text"
                            data-testid="role-select"
                            value={shift.role}
                            style={inputStyle}
                            onChange={(data) => {
                                const currentRole = roles.find((item: RoleDataType) => item.id === data.target.value);
                                dispatch(
                                    updateDraftShifts({
                                        index,
                                        data: { role: data.target.value, position_name: currentRole.name },
                                    }),
                                );
                            }}
                            input={<OutlinedInput style={inputStyle} error={false} />}
                        >
                            {roles.map((role: RoleDataType) => (
                                <MenuItem value={role.id} key={`${role.id}`}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider />
            <Grid container className={classes.contract}>
                <Grid item xs={3} alignItems="flex-end" className={classes.label}>
                    <InputLabel className={classes.labelText} htmlFor={'contractInputId'}>
                        {t('common:start_time')}
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <TimeSelector
                        isStartTime
                        date={shift?.start ?? undefined}
                        onTimeChange={(date) => {
                            dispatch(updateDraftShifts({ index, data: { start: date } }));
                        }}
                        maxTime={shift?.end ? moment(shift.end) : undefined}
                    />
                </Grid>
            </Grid>
            <Grid container className={classes.contract}>
                <Grid item xs={3} alignItems="flex-end" className={classes.label}>
                    <InputLabel className={classes.labelText} htmlFor={'contractInputId'}>
                        {t('common:end_time')}
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <TimeSelector
                        isStartTime={false}
                        date={shift?.end ?? undefined}
                        onTimeChange={(date) => {
                            dispatch(updateDraftShifts({ index, data: { end: date } }));
                        }}
                        minTime={shift?.start ? moment(shift.start) : undefined}
                    />
                </Grid>
            </Grid>

            {isShowWeek && (
                <>
                    <Divider />
                    <Grid container className={classes.dayOfWeekContainer}>
                        <Grid item xs={6} alignItems="flex-start">
                            <InputLabel className={classes.labelText} htmlFor={'contractInputId'}>
                                {t('schedule:apply_to')}
                            </InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.dayOfWeekContainer}>
                        <Grid item xs={12} className={classes.dayOfWeekWrapper}>
                            <Box className={classes.days}>
                                {dayOfWeek.map((item) => (
                                    <Typography key={item.toISOString()} style={{ fontSize: 14 }}>
                                        {format(item, 'EEE')}
                                    </Typography>
                                ))}
                            </Box>
                            <Box className={classes.dayItemWrapper}>
                                {dayOfWeek.map((item) => (
                                    <WeekDateItem
                                        key={item.toISOString()}
                                        date={item}
                                        onClick={() => handlePressDay(item)}
                                        isActive={shift.days.includes(format(item, 'yyyy-MM-dd'))}
                                    />
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

            {index !== 0 && (
                <Grid container className={classes.dayOfWeekContainer}>
                    <Grid item md={2} />
                    <Grid item md={9} justifyContent="flex-end">
                        <Box
                            className={classNames([classes.button, classes.deleteWrapper])}
                            onClick={handleDeleteSection}
                        >
                            <Typography className={classes.deleteText}>{t('common:delete')}</Typography>
                            <DeleteIcon style={{ color: theme.palette.error.dark }} />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export { AddShiftContent };
