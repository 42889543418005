import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createSelfShift, updateSelfShift, deleteSelfShift, getRotasStatus } from 'api/shifts';
import { selectEndDate, selectStartDate } from 'containers/Schedule/store/scheduleSelector';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useSettings } from 'services/useSettings';
import { getShiftContractList } from 'containers/RequestTimeOff/utils/getRevisionsList';
import { useUserContracts } from 'services/useHoliday';
import { AxiosError } from 'axios';

export type CreateShiftRequest = {
    contract_id: string;
    role_id: string;
    start: string;
    end: string;
};

export const useRefetchShift = () => {
    const queryClient = useQueryClient();
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);

    const refetch = () => {
        queryClient.invalidateQueries(['staffShiftAndTimeOff', moment(startDate), moment(endDate)]);
    };

    return { refetch };
};

export const useCreateSelfShift = () => {
    const { refetch } = useRefetchShift();

    const mutation = useMutation((shifts: CreateShiftRequest[]) => createSelfShift({ shifts }), {
        onSuccess: () => {
            refetch();
        },
    });

    return { createSelfShiftMutation: mutation };
};

export const useUpdateSelfShift = () => {
    const { refetch } = useRefetchShift();

    const mutation = useMutation(
        ({ shiftId, shift }: { shiftId: number; shift: CreateShiftRequest }) => updateSelfShift(shiftId, shift),
        {
            onSuccess: () => {
                refetch();
            },
        },
    );

    return { updateSelfShiftMutation: mutation };
};

export const useDeleteSelfShift = () => {
    const { refetch } = useRefetchShift();

    const mutation = useMutation((shift: number) => deleteSelfShift(shift), {
        onSuccess: () => {
            refetch();
        },
    });

    return { deleteSelfShiftMutation: mutation };
};

const getRotasStatusByShopId = async (shopId: number, startWeek: string, allowedRota: string[]) => {
    try {
        const response = await getRotasStatus(shopId, startWeek);
        const status = response.data.data.status;
        return { allowed: status !== 'approved' && allowedRota.includes(status) };
    } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
            return { allowed: true };
        }

        return { allowed: false };
    }
};

const getRotasStatusAllContract = async (shopIds: number[], startDate: string, allowedRota: string[]) => {
    try {
        const results: Record<number, boolean> = {};
        for await (const shopId of shopIds) {
            const response = await getRotasStatusByShopId(shopId, startDate, allowedRota);
            results[shopId] = response?.allowed;
        }
        return results;
    } catch (error) {
        return {};
    }
};

const useGetRotasStatusContracts = (shopIds: number[], startDate: string, allowedRota: string[]) => {
    return useQuery({
        enabled: !!shopIds && !!startDate && shopIds.length > 0,
        queryKey: ['useGetRotasStatusContract', shopIds, startDate],
        queryFn: () => getRotasStatusAllContract(shopIds, startDate, allowedRota),
    });
};

export const useGetRotasStatus = () => {
    const startDate = useSelector(selectStartDate);
    const endDate = useSelector(selectEndDate);
    const { settings } = useSettings();

    const allowed_rota_statuses = settings?.selfServiceShifts?.allowedRotaStatuses;

    const { userContracts } = useUserContracts(startDate, endDate);
    const contracts = getShiftContractList(userContracts).revisions;
    const shopIds = [...new Set(contracts.map((contract) => contract.shopId))];

    const { data: status, isLoading } = useGetRotasStatusContracts(shopIds, startDate, allowed_rota_statuses);

    return {
        isAllowedRota: !status ? false : Object.values(status).some((value) => value),
        isLoading,
        shopRotaStatus: status ?? {},
    };
};
