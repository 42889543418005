import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, DialogContent, Box, Grid, Divider } from '@material-ui/core';
import { useSettings } from 'services/useSettings';
import LoadingComponent from 'components/LoadingComponent';
import React, { useState } from 'react';
import { DialogTitle } from 'components/dialog/DialogTitle';
import DefaultButton from 'components/buttons/DefaultButton';
import { AddShiftContent } from './AddShiftContent';
import { AddShiftResults } from './AddShiftResult';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraftShifts } from 'containers/Schedule/store/scheduleSelector';
import { addMoreDraftShift, resetDraftShifts } from 'containers/Schedule/store/scheduleSlice';
import { isValidShift, transferShiftData } from 'containers/Schedule/helpers';
import { useCreateSelfShift } from 'services/useShift';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';
import { useUserContracts } from 'services/useHoliday';
import { getShiftContractList } from 'containers/RequestTimeOff/utils/getRevisionsList';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '30px 30px',
        minWidth: '640px',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    contract: {
        display: 'flex',
        gap: 20,
    },
    timeWrapper: {
        display: 'flex',
        gap: 20,
        width: '100%',
        justifyContent: 'space-between',
    },
    label: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
    },
    labelApplyDay: {
        alignItems: 'flex-start',
    },
    dialog: {
        padding: 40,
        width: 350,
    },
    dayOfWeekContainer: {
        gap: 20,
    },
    dayOfWeekWrapper: {
        borderColor: '#ECECEC',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        overflow: 'hidden',
    },
    days: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.grey[200],
        padding: '5px 10px',
    },
    dayItemWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
    },
    formControlStyle: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 193,
        },
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonFinishWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
}));

const loadingStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
};

type Props = {
    button: React.ReactNode;
    isVisible: boolean;
    onClose: () => void;
};

export type AddShiftType = {
    department_name?: string;
    position_name?: string;
    role: string;
    start: string;
    end: string;
    days: string[];
    contract: string;
    contract_id?: string;
    role_id?: string;
};

export const AddShiftDialog = ({ onClose, isVisible = false, button }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isSettingsLoading } = useSettings();
    const [isFinish, setIsFinish] = useState(false);
    const draftShifts: AddShiftType[] = useSelector(selectDraftShifts);
    const [failedShifts, setFailedShifts] = useState<AddShiftType[]>();
    const [successShifts, setSuccessShifts] = useState<AddShiftType[]>();
    const { userContracts, isContractsLoading } = useUserContracts();

    const contracts = getShiftContractList(userContracts).revisions;

    const { createSelfShiftMutation } = useCreateSelfShift();

    const isCanSubmit = draftShifts?.every((item: AddShiftType) => isValidShift(item));

    const handleAddAnotherShift = () => {
        if (createSelfShiftMutation.isLoading) return;

        dispatch(addMoreDraftShift());
    };

    const handleClose = () => {
        if (createSelfShiftMutation.isLoading) {
            return;
        }
        dispatch(resetDraftShifts());
        onClose();
        setIsFinish(false);
    };

    const handleSubmit = async () => {
        const shifts = draftShifts?.reduce((result: AddShiftType[], item) => {
            const items = item.days.map(
                (day) =>
                    ({
                        contract_id: item.contract,
                        role_id: item.role,
                        start: `${day} ${item.start.split(' ')[1]}`,
                        end: `${day} ${item.end.split(' ')[1]}`,
                    } as AddShiftType),
            );

            result = [...result, ...items];

            return result;
        }, []);

        const payload = shifts.map((item: AddShiftType) => ({
            contract_id: item.contract_id as string,
            role_id: item.role_id as string,
            start: item.start,
            end: item.end,
        }));

        await createSelfShiftMutation.mutateAsync(payload, {
            onSuccess: ({ data }) => {
                const { failure, success } = data.data;

                const failureData = transferShiftData(failure, contracts);
                const successData = transferShiftData(success, contracts);

                setSuccessShifts(successData);
                setFailedShifts(failureData);

                isCanSubmit && setIsFinish(true);
            },
            onError: () => {
                dispatch(setShowSnackbar({ open: true }));
                dispatch(setSnackBarError({}));
            },
        });
    };

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            {button}
            <Dialog onClose={handleClose} open={isVisible} maxWidth="md">
                <DialogTitle onClose={handleClose}>{t('schedule:add_shift_button')}</DialogTitle>
                <DialogContent className={classes.container}>
                    <Box className={classes.contentWrapper}>
                        {isFinish ? (
                            <>
                                <Grid>
                                    <AddShiftResults successItems={successShifts} failItems={failedShifts} />
                                </Grid>
                                <Box className={classes.buttonFinishWrapper}>
                                    <DefaultButton
                                        size="large"
                                        color="primary"
                                        disableRipple
                                        className={undefined}
                                        variant={'contained'}
                                        onClick={handleClose}
                                    >
                                        {t('common:finish')}
                                    </DefaultButton>
                                </Box>
                            </>
                        ) : (
                            <>
                                {draftShifts?.map((item: AddShiftType, index: number) => (
                                    <>
                                        {index !== 0 && <Divider />}
                                        <AddShiftContent key={`SHIFT_${index}`} shift={item} index={index} />
                                    </>
                                ))}

                                <Divider />

                                <Grid container>
                                    <DefaultButton
                                        size="large"
                                        color="primary"
                                        disableRipple
                                        variant="text"
                                        className={undefined}
                                        onClick={handleAddAnotherShift}
                                    >
                                        {t('portal:add_another')}
                                    </DefaultButton>
                                </Grid>
                                <Box className={classes.buttonWrapper}>
                                    <DefaultButton
                                        size="large"
                                        color="primary"
                                        disableRipple
                                        variant="outlined"
                                        className={undefined}
                                        onClick={handleClose}
                                    >
                                        {t('common:cancel')}
                                    </DefaultButton>
                                    <DefaultButton
                                        size="large"
                                        color="primary"
                                        disableRipple
                                        disabled={!isCanSubmit || createSelfShiftMutation.isLoading}
                                        className={undefined}
                                        variant={'contained'}
                                        onClick={handleSubmit}
                                    >
                                        {t('common:submit')}
                                    </DefaultButton>
                                </Box>
                            </>
                        )}
                    </Box>
                </DialogContent>
                {(createSelfShiftMutation.isLoading || isContractsLoading) && <LoadingComponent style={loadingStyle} />}
            </Dialog>
        </>
    );
};
