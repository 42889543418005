import { Typography, makeStyles, Link, Box } from '@material-ui/core';
import shopworksDarkLogo from 'images/logo/shopworks-dark.svg';
import { ABOUT_SHOPWORKS_LINK, PRIVACY_LINK, HELP_LINK } from './links';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            height: 35,
            backgroundColor: theme.palette.common.white,
        },
    },
    linkWrapper: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            gap: 30,
            marginTop: 5,
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 60,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 10,
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            left: 40,
        },
    },
    linkText: {
        textAlign: 'center',
    },
}));

export const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.wrapper} alignItems="center" justifyContent="center">
            <Box className={classes.logo}>
                <Typography variant="caption">
                    {t('portal:footer.powered_by', { copyright: '' })} &#169; {new Date().getFullYear()}
                </Typography>
                <img src={shopworksDarkLogo} alt="Shopworks logo" />
            </Box>
            <Box className={classes.linkWrapper}>
                <Typography variant="caption" className={classes.linkText}>
                    <Link href={ABOUT_SHOPWORKS_LINK.url} target="_blank" rel="nofollow">
                        {t(ABOUT_SHOPWORKS_LINK.translationKey)}
                    </Link>
                </Typography>
                <Typography variant="caption" className={classes.linkText}>
                    <Link href={PRIVACY_LINK.url} target="_blank" rel="nofollow">
                        {t(PRIVACY_LINK.translationKey)}
                    </Link>
                </Typography>
                <Typography variant="caption" className={classes.linkText}>
                    <Link href={HELP_LINK.url} target="_blank" rel="nofollow">
                        {t(HELP_LINK.translationKey)}
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};
