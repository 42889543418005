const palette = {
    primary: {
        main: '#5C8E1B',
        dark: '#2A4B03',
        light: '#6DA820',
        transparent: 'rgba(0, 225, 0, 0.05)',
    },
    secondary: {
        main: '#1565C0',
        light: '#64B5F6',
    },
    danger: {
        main: '#F44336',
        dark: 'rgb(139, 0, 0)',
        transparent: 'rgba(139, 0, 0, 0.05)',
    },
    warning: {
        main: '#FFC107',
        dark: '#FA6400',
    },
    grey: {
        main: 'rgba(0, 0, 0, 0.54)',
        light: '#D8D8D8',
        lighter: '#FAFAFA',
        lightest: '#A0A0A0',
        soft: '#F3F3F3',
        mid: 'rgba(0, 0, 0, 0.2)',
        deep: '#424242',
        disable: '#BFBFBF',
    },
    common: {
        white: '#FFF',
    },
};

export default palette;
