import DefaultButton from 'components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, Grid, Typography, Divider, Box, IconButton } from '@material-ui/core';
import { useIsMobile } from 'utils/components/helpers';
import { useMoment } from 'containers/languageSelection/DateLocaleProvider';
import { useSettings } from 'services/useSettings';
import { setDialogClosed } from 'containers/Schedule/store/scheduleSlice';
import { selectDialogEvents, selectIsEventDialogOpen } from 'containers/Schedule/store/scheduleSelector';
import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from 'components/LoadingComponent';
import { DialogTitle } from 'components/dialog/DialogTitle';
import { format, parseISO } from 'date-fns';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Info from '@material-ui/icons/Info';
import theme from 'utils/theme';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserContracts } from 'services/useHoliday';
import { getRevisionsList } from 'containers/RequestTimeOff/utils';
import { useDeleteSelfShift, useGetRotasStatus, useUpdateSelfShift } from 'services/useShift';
import { setShowSnackbar, setSnackBarError, setSnackBarSuccess } from 'components/snackbar/eventSnackBarSlice';
import { EditShiftContent } from './EditShiftContent';
import { Close } from '@material-ui/icons';
import { calcRotaStartDate, checkDateIsInWeekFeature, transformShift } from 'containers/Schedule/helpers';
import { useUser } from 'services/auth';
import { DatabaseEdit } from 'mdi-material-ui';

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: '20px 40px',
        [theme.breakpoints.down('md')]: {
            padding: 20,
        },
    },
    header: {
        paddingBottom: 30,
    },
    headerText: {
        fontSize: 20,
        color: theme.palette.primary.main,
    },
    shopScheduleButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingTop: 20,
    },
    itemWrapper: {
        columnGap: 15,
    },
    label: {
        textAlign: 'end',
    },
    primaryText: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 20,
    },
    bold: {
        fontWeight: 'bolder',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    shop: {
        gap: 20,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
    },
    optionWrapper: {
        display: 'flex',
        gap: 5,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        cursor: 'pointer',
        padding: 0,
    },
    deleteText: {
        color: theme.palette.error.dark,
        fontWeight: 'normal',
        fontSize: 14,
    },
    deletePrimary: {
        color: theme.palette.primary.main,
        fontWeight: 'normal',
        fontSize: 14,
    },
    flexStart: {
        justifyContent: 'flex-start',
    },
    disabled: {
        color: '#BFBFBF',
    },
    notEditableText: {
        color: '#919191',
        fontWeight: 'normal',
        fontSize: 14,
    },
    formControlStyle: {
        width: '100%',
    },
    contract: {
        display: 'flex',
        gap: 20,
    },
    mobileNavStyle: {
        height: 56,
        zIndex: 999,
        top: 0,
        left: 0,
        right: 0,
        boxShadow: theme.shadows[1],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    buttonStyle: {
        position: 'absolute',
        top: 4,
        right: 5,
    },
    footerStyle: {
        backgroundColor: 'white',
        position: 'fixed',
        height: '56px',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        boxShadow: theme.shadows[1],
    },
    buttonMobileWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 20,
        padding: '20px 20px',
    },
    flexGrow: {
        display: 'flex',
        flex: 1,
    },
    displayText: {
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    roleFont: {
        fontSize: 18,
    },
    spacing: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20,
        paddingTop: 20,
    },
}));

const loadingStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
};

type Props = {
    tabValue: string;
    handleTabChange: (event: Event, key: string) => void;
};

export const EventDialog = ({ tabValue, handleTabChange }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { moment } = useMoment();
    const { settings, isSettingsLoading } = useSettings();
    const { userDetails } = useUser();
    const currentEventRef = useRef<any>(null);

    const [isEditing, setIsEditing] = useState(false);

    const { userContracts } = useUserContracts();
    const { shopRotaStatus } = useGetRotasStatus();
    let { revisions } = getRevisionsList(userContracts);
    revisions = revisions.filter((item) => shopRotaStatus[item.shopId]);

    const isShopScheduleEnabled = !!settings?.shopSchedule?.enabled;

    const { updateSelfShiftMutation } = useUpdateSelfShift();
    const { deleteSelfShiftMutation } = useDeleteSelfShift();

    const dispatch = useDispatch();
    const dialogEvents = useSelector(selectDialogEvents);
    const isDialogOpen = useSelector(selectIsEventDialogOpen);
    const isMobile = useIsMobile();

    const [currentEvent, setCurrentEvent] = useState(transformShift(dialogEvents?.[0]));

    const isEnableEditBtn = useMemo(() => {
        if (dialogEvents?.[0] && currentEvent) {
            const event = dialogEvents?.[0];

            return (
                event.start !== currentEvent.start ||
                event.end !== currentEvent.end ||
                event.contractId !== currentEvent.contractId ||
                event.roleId !== currentEvent.contractId
            );
        }
    }, [currentEvent, dialogEvents]);

    const currentContract = useMemo(() => {
        if (currentEvent) {
            const currentRevision = revisions.find((item) => item.relatedContractId === currentEvent?.contractId);

            const isInFeature = checkDateIsInWeekFeature(currentEventRef.current?.start);
            if (!isInFeature)
                return {
                    ...currentRevision,
                    selfServiceShifts: false,
                };

            return currentRevision;
        }
    }, [currentEvent, revisions]);

    useEffect(() => {
        if (dialogEvents?.[0]) {
            currentEventRef.current = transformShift(dialogEvents?.[0]);
            setCurrentEvent(transformShift(dialogEvents?.[0]));
        }
    }, [dialogEvents]);

    const handleCloseDialog = () => {
        setIsEditing(false);
        dispatch(setDialogClosed());
    };

    const handleDeleteSection = async () => {
        try {
            await deleteSelfShiftMutation.mutateAsync(currentEvent?.shiftId as number);
            dispatch(setShowSnackbar({ open: true }));
            dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.delete_shift_success' }));
            handleCloseDialog();
        } catch (error) {
            // Error.
            dispatch(setShowSnackbar({ open: true }));
            dispatch(setSnackBarError({}));
        }
    };

    const handleEditShift = async () => {
        try {
            await updateSelfShiftMutation.mutateAsync({
                shiftId: currentEvent?.shiftId as number,
                shift: {
                    contract_id: currentEvent?.contractId as string,
                    role_id: currentEvent?.roleId as string,
                    end: currentEvent?.end as string,
                    start: currentEvent?.start as string,
                },
            });
            dispatch(setShowSnackbar({ open: true }));
            dispatch(setSnackBarSuccess({ message: 'portal:snack_bar.update_shift_success' }));
            handleCloseDialog();
        } catch (error) {
            // Error.
            dispatch(setShowSnackbar({ open: true }));
            dispatch(setSnackBarError({}));
        }
    };

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    if (!currentEvent) return <></>;

    const renderRowInfo = ({ label, value }: { label: string; value?: string }) => {
        return (
            <Grid item container className={classes.itemWrapper}>
                <Grid item md={4} xs={3}>
                    <Typography className={classes.label}>{label}</Typography>
                </Grid>
                <Grid item className={classes.flexGrow}>
                    <Typography className={classes.primaryText}>{value ?? ''}</Typography>
                </Grid>
            </Grid>
        );
    };

    const renderShopSchedule = () => {
        return (
            <>
                {tabValue === 'your-schedule' && isShopScheduleEnabled && (
                    <Grid container justifyContent="center">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                color="primary"
                                className={classes.shopScheduleButton}
                                onClick={(e: Event) => {
                                    handleTabChange(e, 'shop-schedule');
                                    handleCloseDialog();
                                }}
                            >
                                {t('schedule:shop_schedule')}
                            </DefaultButton>
                        </div>
                    </Grid>
                )}
            </>
        );
    };

    const renderEditOption = () => {
        if (!userDetails.canAddShifts) return <></>;

        const isAllowedRotas = shopRotaStatus[currentEvent?.shopId as number];

        // Get end of the current week.
        const endOfCurrentWeek = calcRotaStartDate(new Date()).getTime() + 7 * 24 * 60 * 60 * 1000;
        const isFutureWeek = parseISO(currentEvent.end).getTime() > endOfCurrentWeek;

        if (!currentContract?.selfServiceShifts || !isAllowedRotas || !isFutureWeek) {
            return (
                <Grid container>
                    <Box className={classNames([classes.button, classes.optionWrapper])}>
                        <Info style={{ color: '#919191', fontSize: 16 }} />
                        <Typography className={classes.notEditableText}>{t('common:not_editable')}</Typography>
                    </Box>
                </Grid>
            );
        }

        return (
            <Grid container>
                <Grid item md={6} xs={6}>
                    <Box
                        className={classNames([classes.button, classes.optionWrapper, classes.flexStart])}
                        onClick={() => setIsEditing(true)}
                    >
                        <Typography className={classes.deletePrimary}>{t('schedule:edit_shift')}</Typography>
                        <Edit style={{ color: theme.palette.primary.main, fontSize: 20 }} />
                    </Box>
                </Grid>
                <Grid item md={6} xs={6} justifyContent="flex-end">
                    <Box className={classNames([classes.button, classes.optionWrapper])} onClick={handleDeleteSection}>
                        <Typography className={classes.deleteText}>{t('common:delete')}</Typography>
                        <DeleteIcon style={{ color: theme.palette.error.dark, fontSize: 20 }} />
                    </Box>
                </Grid>
            </Grid>
        );
    };

    return (
        <Dialog
            fullScreen={isMobile}
            onClose={handleCloseDialog}
            open={isDialogOpen}
            maxWidth="md"
            PaperProps={{
                style: {
                    width: isMobile ? '100%' : currentEvent.timeOff ? '400px' : '600px',
                },
            }}
        >
            {!isMobile && !currentEvent.timeOff ? (
                <DialogTitle onClose={handleCloseDialog}>{t('schedule:shift_details')}</DialogTitle>
            ) : (
                <Typography className={classes.buttonStyle}>
                    <IconButton disableRipple onClick={handleCloseDialog}>
                        <Close />
                    </IconButton>
                </Typography>
            )}
            {isDialogOpen && (
                <>
                    <Grid item container xs={12} className={classes.dialog}>
                        <Grid item container xs={12} justifyContent="center" alignItems="center">
                            <Typography className={classes.headerText}>
                                <strong>{moment(currentEventRef.current?.start).format('ddd D MMMM')}</strong>
                            </Typography>
                        </Grid>
                        {!currentEvent.timeOff ? (
                            <Grid item xs={12} className={classes.contentWrapper}>
                                {isEditing ? (
                                    <EditShiftContent
                                        classes={classes}
                                        currentEvent={currentEvent}
                                        revisions={revisions}
                                        setCurrentEvent={setCurrentEvent}
                                    />
                                ) : (
                                    <Box style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                        {renderRowInfo({
                                            label: t('portal:contract'),
                                            value: `${currentEvent?.shopName} - ${currentEvent?.positionName}`,
                                        })}
                                        {renderRowInfo({
                                            label: t('portal:role'),
                                            value: currentEvent?.role as string,
                                        })}
                                        <Grid item container className={classes.itemWrapper}>
                                            <Grid item md={4} xs={3}>
                                                <Typography className={classes.label}>
                                                    {t('common:start_time')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.primaryText}>
                                                    {currentEvent?.start
                                                        ? format(parseISO(currentEvent?.start), 'HH:mm')
                                                        : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4}>
                                                <Typography className={classes.label}>
                                                    {t('common:end_time')}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.primaryText}>
                                                    {currentEvent?.end
                                                        ? format(parseISO(currentEvent?.end), 'HH:mm')
                                                        : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                <Divider />
                                <Grid container className={classes.shop}>
                                    <Box className={classes.row}>
                                        <Typography>{t('portal:department_colon')}</Typography>
                                        <Typography className={classes.bold}>{currentEvent?.departmentName}</Typography>
                                    </Box>
                                    <Box className={classes.row}>
                                        <Typography>{t('portal:shop_colon')}</Typography>
                                        <Typography className={classes.bold}>{currentEvent?.shopName}</Typography>
                                    </Box>
                                </Grid>
                                <Divider />

                                {renderEditOption()}

                                {!isMobile && (
                                    <Box className={classes.buttonWrapper}>
                                        <DefaultButton
                                            size="large"
                                            color="primary"
                                            disableRipple
                                            variant="outlined"
                                            className={undefined}
                                            onClick={handleCloseDialog}
                                        >
                                            {t('common:cancel')}
                                        </DefaultButton>
                                        {isEditing && (
                                            <DefaultButton
                                                size="large"
                                                color="primary"
                                                disableRipple
                                                className={undefined}
                                                variant={'contained'}
                                                onClick={handleEditShift}
                                                disabled={!isEnableEditBtn}
                                            >
                                                {t('common:submit')}
                                            </DefaultButton>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        ) : (
                            <Grid item xs={12} className={classes.spacing}>
                                <Typography
                                    component="h1"
                                    className={classNames(classes.displayText, classes.roleFont)}
                                >
                                    <strong data-testid="event-details-role-label">
                                        {typeof currentEvent.role === 'object'
                                            ? t(currentEvent.role?.key) ?? ''
                                            : currentEvent?.role ?? ''}
                                    </strong>
                                </Typography>
                            </Grid>
                        )}

                        {renderShopSchedule()}

                        {isMobile && !currentEvent.timeOff && (
                            <Grid className={classes.footerStyle}>
                                <Box className={classes.buttonMobileWrapper}>
                                    <DefaultButton
                                        size="large"
                                        color="primary"
                                        disableRipple
                                        variant="outlined"
                                        className={undefined}
                                        onClick={handleCloseDialog}
                                    >
                                        {t('common:cancel')}
                                    </DefaultButton>
                                    {isEditing && (
                                        <DefaultButton
                                            size="large"
                                            color="primary"
                                            disableRipple
                                            className={undefined}
                                            variant={'contained'}
                                            onClick={handleEditShift}
                                            disabled={!isEnableEditBtn}
                                        >
                                            {t('common:submit')}
                                        </DefaultButton>
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
            {(updateSelfShiftMutation.isLoading || deleteSelfShiftMutation.isLoading) && (
                <LoadingComponent style={loadingStyle} />
            )}
        </Dialog>
    );
};
