import CalendarRange from 'mdi-material-ui/CalendarRange';
import { makeStyles, Grid, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RequestTimeOff } from 'containers/RequestTimeOff/RequestTimeOff';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSettings } from '../services/useSettings';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
    },
    labelOnRight: {
        position: 'fixed',
        marginLeft: 80,
        lineHeight: '44px',
    },
    requestTimeOffFab: {
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    },
    requestTimeOffFabLeft: {
        left: 2,
        position: 'absolute',
    },
}));

export const IconButtonRequest = ({ onClick, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        settings: { jiraServiceDeskWidget },
    } = useSettings();

    return (
        <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs>
                <Typography
                    variant="h6"
                    className={classNames({
                        [classes.label]: true,
                        [classes.labelOnRight]: jiraServiceDeskWidget.enabled,
                    })}
                >
                    {t('time_off:request_time_off_button')}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <RequestTimeOff
                    button={
                        <IconButton
                            className={classNames({
                                [classes.requestTimeOffFab]: true,
                                [classes.requestTimeOffFabLeft]: jiraServiceDeskWidget.enabled,
                            })}
                            {...rest}
                        >
                            <CalendarRange color="primary" />
                        </IconButton>
                    }
                    onClick={onClick}
                />
            </Grid>
        </Grid>
    );
};

IconButtonRequest.propTypes = {
    onClick: PropTypes.func.isRequired,
};
