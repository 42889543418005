import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { DayItem } from 'containers/Schedule/localComponents/addShift/DayItem';
import { AddShiftType } from 'containers/Schedule/localComponents/mobileAddShift/AddShiftDialog';
import { parseISO } from 'date-fns';
import { Work } from '@material-ui/icons';
import theme from 'utils/theme';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    textBold: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    textMedium: {
        fontWeight: 'normal',
        fontSize: 14,
    },
    dayWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F8F8F8',
        borderRadius: 3,
        padding: '3px 5px',
        gap: 8,
    },
    error: {
        color: theme.palette.error.dark,
    },
    overlappingWrapper: {
        backgroundColor: '#F8F8F8',
        borderRadius: 4,
        padding: '5px 6px',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        [theme.breakpoints.down('md')]: {
            backgroundColor: 'transparent',
        },
    },
    overlappingText: {
        fontSize: 10,
        color: theme.palette.error.dark,
        fontWeight: 'bold',
    },
}));

type Props = {
    index: number;
    item: AddShiftType;
    isSuccess?: boolean;
};

const ShiftResultItem = ({ index, item, isSuccess = true }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box key={`${index}`}>
            {!isSuccess && (
                <Box className={classes.overlappingWrapper}>
                    <Work style={{ color: theme.palette.error.dark }} fontSize="small" />
                    <Typography className={classes.overlappingText}>{t('schedule:overlapping_shift')}</Typography>
                </Box>
            )}
            <Box className={classes.row} style={{ gap: 16 }}>
                <Typography className={classes.textBold}>{item.contract}</Typography>
                <Typography>{item.role}</Typography>
            </Box>
            <Box className={classes.row} style={{ gap: 16 }}>
                <Typography
                    className={classNames({
                        [classes.textBold]: true,
                        [classes.row]: true,
                    })}
                >
                    {moment(item.start).format('HH:mm')}
                    <Typography className={classes.textMedium}>{` to `}</Typography>
                    {moment(item.end).format('HH:mm')}
                </Typography>
                <Box className={classes.dayWrapper}>
                    {item.days.map((day) => (
                        <DayItem date={parseISO(day)} isSuccess={isSuccess} key={day} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export { ShiftResultItem };
