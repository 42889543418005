import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ControlPanel } from 'containers/Schedule/localComponents/ControlPanel';
import { YourSchedule } from 'containers/Schedule/localComponents/yourSchedule';
import { ShopSchedule } from 'containers/Schedule/localComponents/shopSchedule';
import { HoursWorked } from 'containers/Schedule/localComponents/hoursWorked';
import { MultiWeekView } from 'containers/Schedule/localComponents/multiWeekView';
import { TabbedWrapper } from 'components/TabbedWrapper';
import { EventDialog } from 'containers/Schedule/localComponents/eventDialog/v2/EventDialog';
import { LoggedInNotFound } from 'pages/LoggedInNotFound';
import { sendGoogleAnalyticsTabClick } from '../../googleAnalytics';

import { useSettings } from 'services/useSettings';
import LoadingComponent from 'components/LoadingComponent';

const PATH_PREFIX = '/schedule';

export const ScheduleContent = () => {
    const { t } = useTranslation();
    const { isSettingsLoading, settings } = useSettings();

    const { push } = useHistory();
    const { tabValue } = useParams();

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    const tabs = [
        { name: t('portal:your_schedule'), value: 'your-schedule', eventName: 'Your Schedule', isDisplayed: true },
        {
            name: t('schedule:shop_schedule'),
            value: 'shop-schedule',
            eventName: 'Shop Schedule',
            isDisplayed: !!settings?.shopSchedule?.enabled,
        },
        {
            name: t('portal:hours_worked'),
            value: 'hours-worked',
            eventName: 'Hours Worked',
            isDisplayed: !!settings?.hoursWorked?.enabled,
        },
    ];

    const handleTabChange = (event, value) => {
        const name = tabs.find((tab) => tab.value === value)?.name;

        if (name === undefined) {
            throw new Error(`Cannot find name for ${value}`);
        }
        sendGoogleAnalyticsTabClick(name, 'Schedule');
        push({ pathname: `${PATH_PREFIX}/${value}` });
    };

    const multiWeekViewTabId = 'time-off-planner';

    if (!tabs.some(({ value }) => value === tabValue)) {
        return <LoggedInNotFound />;
    }

    return (
        <>
            <TabbedWrapper
                tabs={tabs}
                color="primary"
                id="hit-point-control-panel"
                tabValue={tabValue}
                handleTabChange={handleTabChange}
            />
            <ControlPanel tabValue={tabValue} multiWeekViewTabId={multiWeekViewTabId} />

            <Switch>
                <Route path={`${PATH_PREFIX}/your-schedule`}>
                    <YourSchedule id="hit-point-your-schedule-calendar" />
                </Route>
                <Route path={`${PATH_PREFIX}/shop-schedule`}>
                    <ShopSchedule id="hit-point-shop-schedule-calendar" />
                </Route>
                <Route path={`${PATH_PREFIX}/hours-worked`}>
                    <HoursWorked id="hit-point-hours-worked" />
                </Route>
                <Route path={`${PATH_PREFIX}/time-off-planner`}>
                    <MultiWeekView />
                </Route>
            </Switch>

            <EventDialog tabValue={tabValue} handleTabChange={handleTabChange} />
        </>
    );
};
