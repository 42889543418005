import { SVGProps } from 'react';

const CheckmarkIcon = ({ width = 26, height = 27, ...props }: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 26 27" {...props}>
        <path
            fill="#5C8E1C"
            fillRule="evenodd"
            d="M24.04 13.5a.98.98 0 1 1 1.96 0c0 7.168-5.832 13-13 13s-13-5.832-13-13S5.832.5 13 .5a.98.98 0 1 1 0 1.96C6.913 2.46 1.96 7.413 1.96 13.5c0 6.088 4.953 11.04 11.04 11.04 6.088 0 11.04-4.952 11.04-11.04Zm-.169-9.447a.98.98 0 1 1 1.384 1.387L12.777 17.885a.98.98 0 0 1-.692.286h-.02a.979.979 0 0 1-.7-.316l-4.933-5.357a.98.98 0 0 1 1.442-1.327l4.242 4.606L23.87 4.053Z"
            clipRule="evenodd"
        />
    </svg>
);
export default CheckmarkIcon;
