import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, DialogContent, Box, Grid, Divider, Button } from '@material-ui/core';
import { useSettings } from 'services/useSettings';
import LoadingComponent from 'components/LoadingComponent';
import React, { useState } from 'react';
import DefaultButton from 'components/buttons/DefaultButton';
import { AddShiftContent } from 'containers/Schedule/localComponents/mobileAddShift/AddShiftContent';
import { AddShiftResults } from 'containers/Schedule/localComponents/addShift/AddShiftResult';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraftShifts } from 'containers/Schedule/store/scheduleSelector';
import { addMoreDraftShift, resetDraftShifts, updateDraftShifts } from 'containers/Schedule/store/scheduleSlice';
import { getContractList, isValidShift, transferShiftData, transformDraftShifts } from 'containers/Schedule/helpers';
import { useCreateSelfShift } from 'services/useShift';
import { setShowSnackbar, setSnackBarError } from 'components/snackbar/eventSnackBarSlice';
import { useUserContracts } from 'services/useHoliday';
import { MobileNav } from './MobileNav';
import { ShiftResultItem } from '../addShift/ShiftResultItem';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 20px',
        gap: 20,
        backgroundColor: '#EEEEEE',
    },

    contract: {
        display: 'flex',
        gap: 20,
    },
    dialog: {
        padding: 40,
        width: 350,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    footerStyle: {
        backgroundColor: 'white',
        position: 'fixed',
        height: '56px',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        boxShadow: theme.shadows[1],
    },
    nextButtonStyle: {
        display: 'flex',
        width: '90%',
        alignSelf: 'center',
        height: 36,
        fontSize: 14,
    },
}));

type Props = {
    button: React.ReactNode;
    isVisible: boolean;
    onClose: () => void;
};

export type AddShiftType = {
    department_name?: string;
    position_name?: string;
    role: string;
    start: string;
    end: string;
    days: string[];
    contract: string;
    contract_id?: string;
    role_id?: string;
    isSaved?: boolean;
};

export const MobileAddShiftDialog = ({ onClose, isVisible = false, button }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isSettingsLoading } = useSettings();
    const [isFinish, setIsFinish] = useState(false);
    const draftShifts: AddShiftType[] = useSelector(selectDraftShifts);
    const [failedShifts, setFailedShifts] = useState<AddShiftType[]>();
    const [successShifts, setSuccessShifts] = useState<AddShiftType[]>();
    const { userContracts } = useUserContracts();
    const contracts = getContractList(userContracts);

    const { createSelfShiftMutation } = useCreateSelfShift();

    const isCanSubmit = draftShifts.every((item: AddShiftType) => isValidShift(item));

    const handleAddAnotherShift = () => {
        if (createSelfShiftMutation.isLoading) return;

        dispatch(addMoreDraftShift());
    };

    const handleClose = () => {
        if (createSelfShiftMutation.isLoading) {
            return;
        }
        dispatch(resetDraftShifts());
        onClose();
        setIsFinish(false);
    };

    const savedShifts = draftShifts.filter((item) => item.isSaved);
    const unsavedShifts = draftShifts.filter((item) => !item.isSaved);

    const handleSubmit = async () => {
        if (unsavedShifts.length) {
            dispatch(
                updateDraftShifts({
                    index: savedShifts.length,
                    data: {
                        isSaved: true,
                    },
                }),
            );

            return;
        }

        if (isFinish) {
            return handleClose();
        }

        const shifts = draftShifts.reduce((result: AddShiftType[], item) => {
            const items = item.days.map(
                (day) =>
                    ({
                        contract_id: item.contract,
                        role_id: item.role,
                        start: `${day} ${item.start.split(' ')[1]}`,
                        end: `${day} ${item.end.split(' ')[1]}`,
                    } as AddShiftType),
            );

            result = [...result, ...items];

            return result;
        }, []);

        const payload = shifts.map((item: AddShiftType) => ({
            contract_id: item.contract_id as string,
            role_id: item.role_id as string,
            start: item.start,
            end: item.end,
        }));

        await createSelfShiftMutation.mutateAsync(payload, {
            onSuccess: ({ data }) => {
                const { failure, success } = data.data;

                const failureData = transferShiftData(failure, contracts);
                const successData = transferShiftData(success, contracts);

                setSuccessShifts(successData);
                setFailedShifts(failureData);

                isCanSubmit && setIsFinish(true);
            },
            onError: () => {
                dispatch(setShowSnackbar({ open: true }));
                dispatch(setSnackBarError({}));
            },
        });
    };

    if (isSettingsLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            {button}
            <Dialog onClose={handleClose} open={isVisible} maxWidth="md" fullScreen>
                <MobileNav onClickNavButton={handleClose} />
                <DialogContent className={classes.container}>
                    <div>
                        {isFinish ? (
                            <AddShiftResults successItems={successShifts} failItems={failedShifts} isMobile />
                        ) : (
                            <Box style={{ rowGap: 10 }}>
                                <Box style={{ marginBottom: 16, gap: 20, display: 'flex', flexDirection: 'column' }}>
                                    {(transformDraftShifts(savedShifts) as AddShiftType[])?.map((item, index) => (
                                        <ShiftResultItem index={index} item={item} key={`SAVED_SHIFT_${index}`} />
                                    ))}
                                </Box>

                                {!!unsavedShifts.length && (
                                    <AddShiftContent
                                        key={`NEW_SHIFT_${savedShifts.length}`}
                                        shift={unsavedShifts[0]}
                                        index={savedShifts.length}
                                    />
                                )}

                                {!unsavedShifts.length && (
                                    <>
                                        <Divider />
                                        <Grid container>
                                            <DefaultButton
                                                size="large"
                                                color="primary"
                                                disableRipple
                                                variant="text"
                                                className={undefined}
                                                onClick={handleAddAnotherShift}
                                            >
                                                {t('portal:add_another')}
                                            </DefaultButton>
                                        </Grid>
                                    </>
                                )}
                            </Box>
                        )}
                        <Box className={classes.buttonWrapper}>
                            <div className={classes.footerStyle}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!isCanSubmit}
                                    className={classes.nextButtonStyle}
                                >
                                    {t('common:add').toUpperCase()}
                                </Button>
                            </div>
                        </Box>
                    </div>
                    <div className={classes.footerStyle}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!isCanSubmit}
                            onClick={handleSubmit}
                            className={classes.nextButtonStyle}
                        >
                            {isFinish
                                ? t('common:finish')
                                : unsavedShifts.length > 0
                                ? t('common:add')
                                : t('common:submit')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
