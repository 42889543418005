import { useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { ClientNotFound } from 'pages/ClientNotFound';
import { SplashScreen } from '../pages/SplashScreen';

import { setInitialDates } from 'containers/Schedule/store/scheduleSlice';
import { useDispatch } from 'react-redux';
import { useTenant } from 'services/auth';
import { useHasMomentLoaded } from 'containers/languageSelection/DateLocaleProvider';
import LoadingComponent from './LoadingComponent';

export const ConfigAuthCheck = ({ children }) => {
    const tenant = useTenant();
    const hasMomentLoaded = useHasMomentLoaded();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setInitialDates());
    }, [dispatch]);

    if (tenant.error) {
        return <ClientNotFound />;
    }

    if (tenant.isLoading) {
        return <SplashScreen />;
    }

    if (!hasMomentLoaded) {
        return <LoadingComponent />;
    }

    return children;
};

ConfigAuthCheck.propTypes = {
    children: PropTypes.node.isRequired,
};
