import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { DayItem } from './DayItem';
import { AddShiftType } from '../mobileAddShift/AddShiftDialog';
import { parseISO } from 'date-fns';

const useStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    textBold: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    textMedium: {
        fontWeight: 'normal',
        fontSize: 14,
    },
    dayWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F8F8F8',
        borderRadius: 3,
        padding: '3px 5px',
        gap: 8,
    },
}));

type Props = {
    index: number;
    item: AddShiftType;
};

const ShiftResultItem = ({ item }: Props) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item md={6} className={classes.row} style={{ gap: 16, marginBottom: 5 }}>
                <Typography className={classes.textBold}>{item.contract}</Typography>
                <Typography>{item.role}</Typography>
            </Grid>
            <Grid md={6} className={classes.row} style={{ gap: 16 }}>
                <Typography
                    className={classNames({
                        [classes.textBold]: true,
                        [classes.row]: true,
                    })}
                >
                    {moment(item.start).format('HH:mm')}
                    <Typography className={classes.textMedium}>{` to `}</Typography>
                    {moment(item.end).format('HH:mm')}
                </Typography>
                <Box className={classes.dayWrapper}>
                    {item.days.map((day) => (
                        <DayItem date={parseISO(day)} isSuccess key={day} />
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};

export { ShiftResultItem };
