const selectScheduleState = (state) => state.schedule;

export const selectStartDate = (state) => selectScheduleState(state).startDate;

export const selectEndDate = (state) => selectScheduleState(state).endDate;
export const selectDefaultStartDate = (state) => selectScheduleState(state).defaultStartDate;
export const selectDefaultEndDate = (state) => selectScheduleState(state).defaultEndDate;
export const selectMwvDate = (state) => selectScheduleState(state).mwvDate;
export const selectIsEventDialogOpen = (state) => selectScheduleState(state).isEventDialogOpen;
export const selectDialogEvents = (state) => selectScheduleState(state).dialogEvents;
export const selectFocusedInput = (state) => selectScheduleState(state).focusedInput;
export const selectDraftShifts = (state) => selectScheduleState(state).draftShifts;
