import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    textBold: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    dayWrapper: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F8F8F8',
        borderRadius: 3,
        padding: '3px 5px',
        gap: 8,
    },
    dayItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 5px',
    },
    success: {
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.dark,
    },
}));

const DayItem = ({ date, isSuccess }: { date: Date; isSuccess: boolean }) => {
    const classes = useStyles();

    return (
        <Box className={classes.dayItem}>
            <Typography
                className={classNames({
                    [classes.textBold]: true,
                    [classes.success]: isSuccess,
                    [classes.error]: !isSuccess,
                })}
            >
                {format(date, 'EEE')}
            </Typography>
            <Typography
                className={classNames({
                    [classes.textBold]: true,
                    [classes.success]: isSuccess,
                    [classes.error]: !isSuccess,
                })}
            >
                {format(date, 'dd')}
            </Typography>
        </Box>
    );
};

export { DayItem };
