import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { calcRotaStartDate, formatRotaDate } from 'containers/Schedule/helpers.js';
import { addDays } from 'date-fns';
import { AddShiftType } from '../localComponents/addShift/AddShiftDialog';

const DEFAULT_SHIFT: AddShiftType = {
    contract: '',
    days: [],
    department_name: '',
    end: '',
    position_name: '',
    role: '',
    start: '',
};

const initialState = {
    startDate: '',
    endDate: '',
    defaultStartDate: '',
    defaultEndDate: '',
    mwvDate: moment().format('YYYY-MM-DD'),
    focusedInput: null,
    isEventDialogOpen: false,
    dialogEvents: [],
    draftShifts: [DEFAULT_SHIFT],
    currentShift: null,
    isYourScheduleLoading: false,
    fakeShifts: [],
    periodSchedule: [],
};

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setInitialDates(state) {
            const today = new Date();

            const start = calcRotaStartDate(today);

            const end = addDays(start, 6);

            const [formattedStart, formattedEnd] = [start, end].map((date) => formatRotaDate(date));

            state.startDate = formattedStart;
            state.defaultStartDate = formattedStart;

            state.endDate = formattedEnd;
            state.defaultEndDate = formattedEnd;
        },
        setStartDate(state, { payload: newDate }) {
            state.startDate = moment(newDate).format('YYYY-MM-DD');
        },
        setEndDate(state, { payload: newDate }) {
            state.endDate = moment(newDate).format('YYYY-MM-DD');
        },
        setMwvDate(state, { payload: newDate }) {
            state.mwvDate = moment(newDate).format('YYYY-MM-DD');
        },
        handlePaginationNext(state, { payload: isMultiWeekViewTab }) {
            if (isMultiWeekViewTab) {
                state.mwvDate = moment(state.mwvDate).add(1, 'months').format('YYYY-MM-DD');
            } else {
                state.startDate = moment(state.startDate).add(7, 'days').format('YYYY-MM-DD');
                state.endDate = moment(state.endDate).add(7, 'days').format('YYYY-MM-DD');
            }
        },
        handlePaginationBack(state, { payload: isMultiWeekViewTab }) {
            if (isMultiWeekViewTab) {
                state.mwvDate = moment(state.mwvDate).subtract(1, 'months').format('YYYY-MM-DD');
            } else {
                state.isYourScheduleLoading = true;

                state.startDate = moment(state.startDate).subtract(7, 'days').format('YYYY-MM-DD');
                state.endDate = moment(state.endDate).subtract(7, 'days').format('YYYY-MM-DD');
            }
        },
        handleDatesChange(state, { payload: event }) {
            const clickedStart = event.startDate;
            const newStart = calcRotaStartDate(clickedStart);
            const newEnd = moment(newStart).add(6, 'days').format('YYYY-MM-DD');

            state.startDate = moment(newStart).format('YYYY-MM-DD');
            state.endDate = moment(newEnd).format('YYYY-MM-DD');
        },
        handleResetDates(state, { payload: isMultiWeekViewTab }) {
            if (isMultiWeekViewTab) {
                state.mwvDate = moment().format('YYYY-MM-DD');
            } else {
                state.startDate = moment(state.defaultStartDate).format('YYYY-MM-DD');
                state.endDate = moment(state.defaultEndDate).format('YYYY-MM-DD');
            }
        },
        onFocusChange(state, { payload: input }) {
            if (input === 'startDate') {
                state.focusedInput = input;
            } else {
                state.focusedInput = null;
            }
        },
        setDialogOpen(state) {
            state.isEventDialogOpen = true;
        },
        setDialogClosed(state) {
            state.isEventDialogOpen = false;
        },
        setDialogEvents(state, { payload: newDialogEvent }) {
            state.dialogEvents = newDialogEvent;
        },
        updateDraftShifts: (state, action) => {
            const { index, data } = action.payload;
            state.draftShifts[index] = {
                ...state.draftShifts[index],
                ...data,
            };
        },
        addMoreDraftShift: (state) => {
            state.draftShifts = [...state.draftShifts, DEFAULT_SHIFT];
        },
        resetDraftShifts: (state) => {
            state.draftShifts = [DEFAULT_SHIFT];
        },
        deleteDraftShift: (state, action) => {
            const { index } = action.payload;
            state.draftShifts = state.draftShifts.filter((_, currentIndex) => currentIndex !== index);
        },
    },
});

export const {
    reducer: ScheduleReducer,
    actions: {
        setInitialDates,
        setStartDate,
        setEndDate,
        setMwvDate,
        handlePaginationNext,
        handlePaginationBack,
        handleDatesChange,
        handleResetDates,
        onFocusChange,
        setDialogOpen,
        setDialogClosed,
        setDialogEvents,
        updateDraftShifts,
        addMoreDraftShift,
        resetDraftShifts,
        deleteDraftShift,
    },
} = scheduleSlice;
