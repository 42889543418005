import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import TimeSelector from 'components/TimeSelector';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import theme from 'utils/theme';
import { DialogEventType } from './EventDetails';
import { RevisionDataType } from 'containers/Schedule/type/scheduleType';
import { useMemo } from 'react';

type Props = {
    currentEvent: DialogEventType;
    classes: any;
    revisions: RevisionDataType[];
    setCurrentEvent: (event: any) => void;
};

const EditShiftContent = ({ currentEvent, classes, revisions, setCurrentEvent }: Props) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { inputStyle } = (theme as unknown).requestTimeOffForm;
    const { t } = useTranslation();

    const roles = useMemo(() => {
        const currentContract = revisions.find(
            (item: { contractId: number | string }) => item.contractId === currentEvent.contractId,
        );
        return currentContract?.roles ?? [];
    }, [currentEvent.contractId, revisions]);

    return (
        <>
            <Grid container className={classes.contract}>
                <Grid item md={2} xs={3} className={classes.label}>
                    <InputLabel id="select-label" htmlFor={'contractInputId'}>
                        {t('portal:contract')}:
                    </InputLabel>
                </Grid>
                <Grid item md={9} xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <Select
                            id="contractInputId"
                            name="contract"
                            type="text"
                            aria-labelledby="select-label"
                            data-testid="contract-select"
                            value={currentEvent.contractId ?? ''}
                            onChange={(data) => {
                                setCurrentEvent(
                                    (event: any) =>
                                        ({
                                            ...event,
                                            contractId: data.target.value,
                                        } as DialogEventType),
                                );
                            }}
                            input={<OutlinedInput style={inputStyle} error={false} />}
                        >
                            {revisions.map((contract) => (
                                <MenuItem value={contract.contractId} key={contract.id}>
                                    {`${contract.shopName} - ${contract.positionName}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className={classes.contract}>
                <Grid item md={2} xs={3} className={classes.label}>
                    <InputLabel id="role-select" htmlFor={'contractInputId'}>
                        {t('portal:role')}:
                    </InputLabel>
                </Grid>
                <Grid item md={9} xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <Select
                            id="roleId"
                            name="role"
                            type="text"
                            aria-labelledby="role-select"
                            data-testid="role-select"
                            value={currentEvent.roleId}
                            style={inputStyle}
                            onChange={(data) => {
                                setCurrentEvent(
                                    (event: any) =>
                                        ({
                                            ...event,
                                            roleId: data.target.value,
                                        } as DialogEventType),
                                );
                            }}
                            input={<OutlinedInput style={inputStyle} error={false} />}
                        >
                            {roles.map((role) => (
                                <MenuItem value={role.id} key={`${role.id}`}>
                                    {role.name as string}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className={classes.contract}>
                <Grid item md={2} xs={3} className={classes.label}>
                    <InputLabel htmlFor={'contractInputId'}>{`${t('common:start_time')}:`}</InputLabel>
                </Grid>
                <Grid item md={3} xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <TimeSelector
                            isStartTime
                            date={currentEvent?.start ?? undefined}
                            onTimeChange={(date) => {
                                setCurrentEvent(
                                    (event: any) =>
                                        ({
                                            ...event,
                                            start: date,
                                        } as DialogEventType),
                                );
                            }}
                            maxTime={currentEvent?.end ? moment(currentEvent.end) : undefined}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={2} xs={3} className={classes.label}>
                    <InputLabel htmlFor={'contractInputId'}>{`${t('common:end_time')}:`}</InputLabel>
                </Grid>
                <Grid item md={3} xs={8}>
                    <FormControl variant="outlined" className={classes.formControlStyle}>
                        <TimeSelector
                            isStartTime={false}
                            date={currentEvent?.end ?? undefined}
                            onTimeChange={(date) => {
                                setCurrentEvent((event: any) => ({
                                    ...event,
                                    end: date,
                                }));
                            }}
                            minTime={currentEvent?.start ? moment(currentEvent.start) : undefined}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export { EditShiftContent };
