import { makeStyles, Tooltip } from '@material-ui/core';
import { TimePicker } from '@mui/x-date-pickers';
import { memo, useState } from 'react';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import theme from 'utils/theme';
import moment, { Moment } from 'moment';
import { useSettings } from 'services/useSettings';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

type Props = {
    minTime?: Moment;
    maxTime?: Moment;
    isStartTime?: boolean;
    onTimeChange: (time: string) => void;
    date?: string;
};

const usStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        gap: 10,
    },
    timePicker: {
        width: '100%',
    },
    tooltip: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.common.white,
        fontSize: '0.75rem',
        padding: '10px 12px',
        borderRadius: '6px',
        boxShadow: theme.shadows[3],
    },
    arrow: {
        color: theme.palette.error.dark,
    },
}));

const TimeSelector = ({ minTime, maxTime, onTimeChange, date }: Props) => {
    const classes = usStyles();
    const { t } = useTranslation();

    const { settings } = useSettings();
    const segmentMinutes = settings?.rota?.segmentMinutes;
    const [error, setError] = useState<boolean>(false);

    const checkIsValidTime = (date?: Date) => {
        return date && date.getMinutes() % segmentMinutes === 0;
    };

    return (
        <Tooltip
            title={error ? t('schedule:shift_segment_validation', { segmentMinutes }) : ''}
            arrow
            placement="right"
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
            <span>
                <TimePicker
                    className={classes.timePicker}
                    sx={{
                        '.MuiOutlinedInput-input': {
                            padding: '5px',
                        },
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.primary.main,
                        },
                        '.MuiClock-amButton': {
                            color: 'white',
                            backgroundColor: 'green',
                        },
                    }}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    onError={(_, value) => {
                        setError(!checkIsValidTime(value?.toDate()));
                    }}
                    slotProps={{
                        textField: {
                            placeholder: '',
                        },
                    }}
                    minutesStep={segmentMinutes}
                    minTime={minTime}
                    maxTime={maxTime}
                    value={date ? moment(date) : undefined}
                    onChange={(date) => {
                        const isValidTime = checkIsValidTime(date?.toDate());
                        if (!isValidTime) {
                            return onTimeChange('');
                        }
                        date?.toDate() && onTimeChange(format(date?.toDate(), 'yyyy-MM-dd HH:mm'));
                    }}
                    ampm={false}
                />
            </span>
        </Tooltip>
    );
};

export default memo(TimeSelector);
