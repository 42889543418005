import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import { AddShiftType } from './AddShiftDialog';
import { useTranslation } from 'react-i18next';
import CircleErrorIcon from 'containers/Schedule/localComponents/addShift/icons/CircleErrorIcon';
import CheckmarkIcon from 'containers/Schedule/localComponents/addShift/icons/CheckmarkIcon';
import { ShiftResultItem } from 'containers/Schedule/localComponents/addShift/ShiftResultItem';
import { ShiftResultItem as ShiftResultItemMobile } from 'containers/Schedule/localComponents/mobileAddShift/ShiftResultItem';

const useStyles = makeStyles(() => ({
    container: {
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    textBold: {
        fontWeight: 'bold',
    },
}));

type Props = {
    successItems?: AddShiftType[];
    failItems?: AddShiftType[];
    isMobile?: boolean;
};

const AddShiftResults = ({ failItems, successItems, isMobile = false }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const ItemWrapper = isMobile ? ShiftResultItemMobile : ShiftResultItem;

    return (
        <Box className={classes.container}>
            {!!successItems?.length && (
                <Box className={classes.row}>
                    <CheckmarkIcon />
                    <Typography className={classes.textBold}>{t('schedule:add_shift_success')}</Typography>
                </Box>
            )}
            {successItems?.map((item, index) => (
                <ItemWrapper index={index} item={item} key={`SUCCESS_ITEM_${index}`} />
            ))}
            {!!failItems?.length && (
                <>
                    <Divider />
                    <Box className={classes.row}>
                        <CircleErrorIcon />
                        <Typography className={classes.textBold}>{t('schedule:add_shift_fail')}</Typography>
                    </Box>
                </>
            )}
            {failItems?.map((item, index) => (
                <ItemWrapper index={index} item={item} key={`FAIL_ITEM_${index}`} />
            ))}
        </Box>
    );
};

export { AddShiftResults };
