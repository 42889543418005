import { makeStyles, Typography, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    mobileNavStyle: {
        height: 56,
        zIndex: 999,
        top: 0,
        left: 0,
        right: 0,
        boxShadow: theme.shadows[1],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    buttonStyle: {
        position: 'absolute',
        top: 4,
        left: 5,
    },
    titleStyle: {
        fontSize: '1rem',
    },
}));

type Props = {
    onClickNavButton: () => void;
};

export const MobileNav = ({ onClickNavButton }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.mobileNavStyle}>
            <Typography className={classes.buttonStyle}>
                <IconButton disableRipple onClick={onClickNavButton}>
                    <Close />
                </IconButton>
            </Typography>

            <Typography variant="caption" className={classes.titleStyle}>
                {t('schedule:add_shift_button')}
            </Typography>
        </div>
    );
};
