import { Card, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { LoginForm } from './loginGrid/LoginForm';
import { Footer } from 'components/layouts/Footer';
import { LanguageSelection } from 'containers/languageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';
import { supportedBrowsers } from 'supportedBrowsers';
import { useTenant } from 'services/auth';
import NativeAppBanner from 'components/nativeAppBanner';

const useStyles = makeStyles((theme) => ({
    alert: {
        maxWidth: '1000px',
        margin: '0 20px 20px',
        [theme.breakpoints.up('sm')]: {
            margin: '0 30px 20px',
        },
        '& > .MuiAlert-message': {
            padding: '8px 15px',
        },
    },
    footerPosition: {
        paddingTop: 20,
        paddingBottom: 20,
        width: 280,
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            boxSizing: 'content-box',
            margin: 'auto 0 0',
            width: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            height: 35,
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: theme.palette.common.white,
        },
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0 auto 18px',
        minWidth: 375,
        [theme.breakpoints.up('sm')]: {
            margin: '20px 30px 20px 0',
            justifyContent: 'flex-end',
        },
        [theme.breakpoints.down('md')]: {
            margin: '20px 30px 20px 0',
            justifyContent: 'flex-end',
        },
    },
    loginContainer: {
        paddingTop: 20,
        backgroundColor: theme.palette.grey.soft,
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'spaceBetween',
            minHeight: '100vh',
            paddingTop: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: '100vh',
        },
    },
    loginFormPanel: {
        boxShadow: `0 0 10px 0 ${theme.palette.grey.light}`,
        minHeight: 500,
        width: 300,
        [theme.breakpoints.up('sm')]: {
            width: 400,
        },
    },
    loginWhitePanel: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: theme.palette.common.white,
            padding: '40px 0',
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.common.white,
        },
    },
    logoContainer: {
        paddingBottom: 20,
    },
}));

export const LoginGrid = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { data: tenant } = useTenant();
    const { name, logo } = tenant;

    const isSupported = supportedBrowsers.test(window.navigator.userAgent);

    return (
        <div className={classes.loginContainer}>
            <div className={classes.header}>
                <LanguageSelection />
            </div>

            <div className={classes.loginWhitePanel}>
                {logo && (
                    <div className={classes.logoContainer}>
                        <img src={logo} alt={name} height="55" />
                    </div>
                )}
                {!isSupported && (
                    <Alert className={classes.alert} severity="error">
                        {t('portal:browser_unsupported')}
                    </Alert>
                )}
                <Card className={classes.loginFormPanel}>
                    <LoginForm />
                </Card>
                <NativeAppBanner />
            </div>

            <div className={classes.footerPosition}>
                <Footer />
            </div>
        </div>
    );
};
