import { apiClient } from '../utils/data/api';

export const getStaffShiftsInPeriod = (start, end) => apiClient().get(`shifts?start=${start}&end=${end}`);

export const createSelfShift = (shift) => apiClient().post('shifts', shift);

export const updateSelfShift = (shiftId, shift) => apiClient().post(`shifts/${shiftId}`, shift);

export const deleteSelfShift = (shiftId) => apiClient().delete(`shifts/${shiftId}`);

export const getRotasStatus = (shopId, startWeek) =>
    apiClient().get(`rotas/status?shop_id=${shopId}&week_commence_date=${startWeek}`);
